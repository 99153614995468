import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import { linkFor } from "utils";

export default function Home() {
  const { data: session } = useSession();
  const router = useRouter();

  if (router.isReady) {
    const redirectUrl = session ? linkFor("dashboard") : linkFor("login");
    router.push(redirectUrl);
  }

  return "Loading…";
}
